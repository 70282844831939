import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-7.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import yeldo1 from "../../assets/images/sp/7/yeldo-1.jpg";
import yeldo2 from "../../assets/images/sp/7/yeldo-2.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Yeldo | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 7</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.yeldo.com/" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Yeldo SA</h6>
                                <h1>The new way to invest in real estate</h1>
                                <p>Yeldo è la piattaforma svizzera che consente ad investitori qualificati di poter
                                    accedere ad investimenti immobiliari di rango istituzionale.</p>
                                <p>Con sede a Lugano e Zurigo, Yeldo è una società che combina tecnologia e competenze
                                    decennali in ambito finanziario e immobiliare, con l’obiettivo di offrire
                                    un’esperienza di investimento unica.</p>
                                <p>Tutte le proposte di investimento sono sottoposte ad un processo di selezione
                                    approfondito e rigoroso e tutte le informazioni e gli aggiornamenti in merito agli
                                    investimenti attivi rimangono sempre accessibili nell’area personale
                                    dell’investitore presente sul nostro portale.</p>
                                <p>Investendo con Yeldo è dunque possibile creare il proprio portafoglio di investimento
                                    creando un mix di rendimenti costanti o opportunistici a seconda del proprio profilo
                                    di rischio-rendimento.</p>
                                <p>Unisciti a Yeldo e accedi alla rete più esclusiva di investitori in Svizzera!</p>
                                <p>Maggiori informazioni su <a href="https://www.yeldo.com/" target="_blank">www.yeldo.com</a></p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-justify">
                                <img src={yeldo1} alt="Yeldo"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                <h6><b>Antonio Borgonovo</b>, CEO and Founder</h6>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24}} className="text-center">
                                <a href="https://www.yeldo.com/" target="_blank">
                                <img src={yeldo2} alt="Yeldo"
                                     className="sp-img d-inline-block align-top mb-4"/>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
